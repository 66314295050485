
@media (max-width: 1519px) {
  .package {
    // border-color: rgb(200, 200, 200) !important;
    transform: scale(1) !important;
  }

  .packages--container {
    max-width: 1120px;
  }
}

@media (max-width: 700px) {
  .packages__guidance--title {
    font-size: 40px;
  }
}
