@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flexCenterHorizontal() {
  display: flex;
  justify-content: center;
}

@mixin flexCollCenterVert() {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

@mixin flexCollCenterHorizontal() {
  display: flex;
  align-items: center;
  flex-direction: column;
}

@mixin flexCollumn() {
  display: flex;
  flex-direction: column;
}

@mixin flexCenterVert() {
  display: flex;
  align-items: center;
}

@mixin flexCentered() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flexCenteredCollumn() {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@mixin blurEffect() {
  filter: blur(10px);
}