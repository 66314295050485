@media (max-width: 1150px) {
  footer {
    .row {
      grid-template-columns: auto auto;
    }
  }
}

@media (max-width: 850px) {
  .left:has(figure),
  .right:has(figure) {
    display: none !important;
  }

  .left,
  .right {
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
  }

  .sidebar--btn {
    display: flex;
  }

  .header--links {
    display: none;
  }

  nav {
    left: 10px;
    right: 10px;

    .btn--arrow {
      display: none;
    }
  }
}

@media (max-width: 750px) {
  footer {
    .row {
      grid-template-columns: auto;
    }
  }

  .footer--section {
    padding: 0 14px;
  }

  .title {
    font-size: 40px;
  }

  .cookie--banner {
    left: 00px;
    right: 00px;
    width: auto;
  }
}

@media (max-width: 400px) {
  p:has(.splitter) {
    display: flex;
    flex-direction: column;
    .splitter {
      width: 0;
      height: 0;
      margin-bottom: 8px;
    }
  }

  .footer__copyright--container {
    .footer__privacy--policy--link {
      display: none;
    }

    .footer__copyright--para {
      padding: 0;
      max-width: none;
    }
  }
}
