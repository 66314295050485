@use "../Config/variables.scss" as *;
@use "../Config/mixins.scss" as *;
@use "sass:map";

#packages-page {
  margin-top: 100px;

  .btn--arrow {
    background-color: map.get($colors, primary-1);
    color: white;
    margin: 0;
  }
}

#packages {
  width: 100vw;

  .row {
    @include flexCollCenterHorizontal;
    max-width: none;
  }

  @include flexCentered;
}

.packages--container {
  width: 100%;
  @include flexCenterHorizontal;
  flex-wrap: wrap;
}

.package {
  width: 350px;
  padding: 28px;
  margin: 12px 12px;
}

.package--title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}

.packages--sub-title {
  max-width: 680px;
  text-align: center;
}

.package--price {
  font-size: 48px;
}

.price__per {
  font-size: 14px;
  font-weight: 500;
}

.package__exclusive {
  margin-top: 2px;
  font-size: 12px;
  margin-bottom: 22px;
}

.package--items {
  @include flexCollumn;
  align-items: flex-start;
  width: 100%;
  border-top: 1px solid rgba(128, 128, 128, 0.391);
  padding: 22px 0;
}

.package--item {
  display: flex;
  width: 100%;
  margin: 8px 0;
}

.package__item--icon {
  margin-top: 2px;
  padding: 4px;
  border-radius: 20px;
  color: white;
  height: 16px;
  width: 16px;
  background-color: map.get($colors, primary-1);
  margin-right: 10px;
}

.package--btn {
  margin-top: auto;
  width: 100%;
  height: 50px;
  padding: 16px 12px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 6px;
  border: none;
  color: white;
  @include flexCentered;
  text-decoration: none;
  background-color: map.get($colors, primary-1);
  margin: 40px 0;
}

.package--para {
  line-height: 24px;
  padding: 0 4px;
  margin-bottom: 24px;
}

#packages--guidance {
  min-height: 60vh;
  margin: 60px 0;
  @include flexCentered;

  .row {
    @include flexCentered;
  }
}
/*

FAQ

*/
#faq {
  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 24px;
  }

  .title {
    font-size: 36px;
    width: 100%;
    text-align: left;
  }
}

.faq--container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.question {
  overflow: hidden;
  padding-top: 24px;
  margin-top: 24px;
  position: relative;
  border-top: 1px solid rgb(190, 190, 190);
}

.question--top {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
}

.question--icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

.question--open {
  height: auto;

  .faq--para {
    display: block;
  }
}

.faq--title {
  font-size: 18px;
  font-weight: 600;
}

.faq--para {
  margin: 0;
  padding: 0;
  padding-top: 8px;
  display: none;
  font-size: 16px;
  line-height: 160%;
}
