@use "../Config/variables.scss" as *;
@use "../Config/mixins.scss" as *;
@use "sass:map";

#services-landing {
  display: flex;
  align-items: center;
  min-height: 100vh;
  color: white;

  .para {
    color: map.get($colors, light-gray);
  }

  .row {
    max-width: 1400px;
  }
}

.services__hero--wrapper {
  display: flex;
  position: relative;
  align-items: center;
  height: 550px;
  width: 100%;
  background: linear-gradient(
    to right bottom,
    rgb(26, 32, 44),
    rgb(45, 55, 72)
  );
  border-radius: 30px;
  padding: 80px;

  .title {
    font-size: 48px;
    margin-bottom: 24px;
  }

  .para {
    font-size: 14px;
  }
}

.services__bg--wrapper {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  filter: blur(50px);
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
}

.services__hero--info-container {
  width: 55%;
  z-index: 2;
}

.services__hero--img-wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 80px;
  height: 648px;
  width: 432px;
  overflow: hidden;
  border-radius: 12px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

#expertise {
  .title {
    font-size: 36px;
  }

  .flex-row {
    align-items: start;
    justify-content: space-between;
    max-width: 1240px;
  }

  .left {
    padding-right: 70px;

    .para {
      font-size: 18px;
    }
  }

  .right {
    align-items: flex-end;
  }
}

.expert__img--wrapper {
  width: 608px;
  height: 608px;
  overflow: hidden;
  background-color: map.get($colors, primary-1);
  border-radius: 30px;
  position: relative;
}

.expert__img--line {
  width: 610px;
  height: 200%;
  position: absolute;
  transform: rotate(30deg);
  top: -80px;
  right: -80px;
  background-color: map.get($colors, primary-2);
  z-index: 1;
  filter: blur(20px);
}

.expert__img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  left: 64px;
  top: 64px;
  z-index: 2;
  border-radius: 20px;
  overflow: hidden;
}

.expert__point {
  display: flex;
  margin-top: 32px;
}

.expert__icon--wrapper {
  width: 40px;
  color: map.get($colors, primary-1);
  fill: map.get($colors, primary-1);
  font-size: 20px;
}

.expert__title {
  font-size: 16px;
  margin-bottom: 10px;
}

.expert__para {
  font-size: 16px;
  line-height: 30px;
}
