@media (max-width: 976px) {
  .about--title {
    font-size: 80px;
  }
}

@media (max-width: 850px) {
  #founder {
    .flex-row {
      flex-direction: column;
    }

    .left {
      display: flex !important;
      max-height: 50vh;

      .founder__img--wrapper {
        width: auto;
        margin-bottom: 20px;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

@media (max-width: 817px) {
  .about--title {
    font-size: 60px;
  }

  .white-line {
    display: none;
  }

  .about__header-bottom {
    flex-direction: column;
    width: 100%;

    p {
      width: 100%;
    }

    .white-line {
      display: block;
      width: 100%;
      margin: 20px 0;
    }
  }
}

@media (max-width: 635px) {
  .about--title {
    font-size: 48px;
  }
}

@media (max-width: 500px) {
  .about--title {
    font-size: 36px;
  }

  #founder {
    .stats--container {
      flex-direction: column;
      align-items: center;

      .stat {
        align-items: center;
        margin-bottom: 20px;
        margin-right: 0;

        .stat--number {
          font-size: 50px;
        }
      }
    }
  }

  #about-page {
    section {
      .title {
        font-size: 30px;
      }

      .para {
        font-size: 14px !important;
      }
    }
  }

  #founder {
    .founder__top-short {
      display: none;
    }
  }
}
