@media (max-width: 1000px) {
  #contact {
    .row {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .contact--right,
  .contact--left {
    margin: 0;
    padding: 0;
    width: 80%;
  }
}

@media (max-width: 650px) {
  .contact--right,
  .contact--left {
    width: 100%;
  }

  .contact--title {
    font-size: 28px;
  }

  .contact--sub-title {
    font-size: 16px;
  }

  #contact {
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0;
    }
  }
}