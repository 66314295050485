@use "../Config/variables.scss" as *;
@use "../Config/mixins.scss" as *;
@use "sass:map";

/*

CONTACT

*/

#contact {
  width: 100vw;
  min-height: 100vh;

  .container {
    padding-top: 100px;
    height: 100%;

    .row {
      height: 100%;
      display: flex;
      padding-top: 80px;
    }
  }

  background: rgb(89, 75, 238);
  background: -moz-linear-gradient(
    90deg,
    rgba(89, 75, 238, 1) 0%,
    rgba(248, 152, 230, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(89, 75, 238, 1) 0%,
    rgba(248, 152, 230, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(89, 75, 238, 1) 0%,
    rgba(248, 152, 230, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#594bee",endColorstr="#f898e6",GradientType=1);

  overflow-y: visible;
}

.contact--title {
  color: white;
  margin-bottom: 18px;
  font-size: 38px;
  font-weight: 900;
}

.contact--sub-title {
  color: white;
  line-height: 28px;
  font-weight: 500;
}

#contact--form {
  @include flexCollumn;
  position: relative;
}

.same-line {
  display: flex;
}

.contact__input--container {
  @include flexCollumn;
  margin-bottom: 24px;
}

.contact__label {
  color: white;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;
}

.contact--input,
textarea {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.501);
  height: 42px;
  min-height: 42px;
  padding: 12px;
  border-radius: 6px;
  min-width: 100%;
  max-width: 100%;
  font-size: 14px;
  color: white;
  font-weight: 500;
}

textarea {
  height: 100px;
}

.contact--right {
  display: flex;
  margin-left: auto;
  justify-content: flex-start;
  width: 50%;
  padding-left: 28px;
}

.contact--left {
  width: 50%;
  padding-right: 28px;
  display: block;
}

.same-line {
  display: flex;
  justify-content: space-between;

  .contact__input--container {
    width: 49%;
  }
  input {
    width: 100%;
  }
}

.contact--btn {
  margin-top: 0;
}

.contact--success {
  width: 100%;
  height: 100%;
  background-color: lightgreen;
  position: absolute;
  border-radius: 4px;
  @include flexCentered;

  svg {
    color: white;
    font-size: 58px;
  }
}

.contact--loading {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: lightgray;
  border-radius: 4px;
  @include flexCentered;
}

.captcha__error {
  color: white;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.44);
}

.contact--info {
  color: white;
  line-height: 26px;
}

option {
  color: black;
  padding: 4px;
}

.btn {
  margin-top: 10px;
}
