#privacy-page {
  padding-top: 80px;

  h1 {
    font-size: 38px;
    padding-bottom: 20px;
  }

  p {
    font-size: 16px;
    line-height: 150%;

    padding-bottom: 20px;
  }

  h2 {
    padding-top: 40px;
    padding-bottom: 8px;
  }

  ul {
    padding: 0 24px;
  }

  li {
    padding-bottom: 12px;
  }
}