@media (max-width: 1300px) {
  .testimonial__pfp--container {
    width: 300px;
    height: 300px;
  }

  .testimonial--right {
    height: 300px;
  }

  .testimonial__para {
    font-size: 20px;
  }

  .testimonial__name {
    font-size: 26px;
  }

  .testimonial__job {
    font-size: 20px;
  }

  .testimonial {
    padding: 0;
  }
}

@media (max-width: 1200px) {
  .skills__bars--container {
    justify-content: center;
  }

  .skill__bar--wrapper {
    width: 100%;
  }

  .designs--container {
    width: auto;
    max-width: 100vw;
    justify-content: start;
    align-items: start;
  }

  .design {
    width: 330px;
  }
}

@media (max-width: 900px) {
  #landing {
    min-height: 100vh;

    .row {
      flex-direction: column;
    }

    .left,
    .right {
      width: 100%;
    }

    .left {
      margin-top: 80px;
      margin-bottom: 40px;
    }

    .landing--right {
      justify-content: center;
      padding-bottom: 40px;
    }
  }
}

@media (max-width: 850px) {
  #landing {
    .left {
      margin-top: 80px !important;
      margin-bottom: 40px !important;
    }

    .landing--right {
      padding-bottom: 40px !important;
    }
  }

  .testimonials--container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .testimonial {
    flex-direction: column;
    width: 100%;
    max-width: 450px;
    background-color: rgb(248, 248, 248);
    border-radius: 8px;
    padding: 16px;
  }

  .testimonial__pfp--container {
    width: 100%;
    height: 400px;
    border-radius: 4px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .testimonial--right {
    height: auto;
    padding: 0;
    margin: 0;
    width: 100%;
    margin-top: 14px;
  }

  .testimonial__para {
    margin-bottom: 16px;
  }

  .landing--title {
    font-size: 40px;
  }

  .testimonial_prev,
  .testimonial_next {
    transform: none;
    top: 190px;
  }

  .testimonial_prev {
    left: 0;
  }

  .testimonial_next {
    right: 0;
  }
}

@media (max-width: 500px) {
  .testimonial__para {
    font-size: 16px;
  }

  .testimonial__name {
    font-size: 22px;
  }

  .testimonial__job {
    font-size: 16px;
  }
}

@media (max-width: 420px) {
  #landing {
    text-align: center;

    .left {
      align-items: center;
    }
  }

  .landing--right {
    display: none;
  }
}
