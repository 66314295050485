@media (max-width: 1150px) {
  .services__hero--wrapper {
    padding: 40px;
  }

  .services__hero--img-wrapper {
    right: 40px;
    width: 380px;
  }

  .expert__img--wrapper {
    width: 500px;
    height: 500px;
  }

  .expert__img {
    left: 40px;
    top: 40px;
  }
}

@media (max-width: 950px) {
  #services-landing {
    padding-bottom: 300px;

    .container {
      padding: 0;
      margin: 0;
    }

    .row {
      padding: 0;
      margin: 0;
    }
  }

  .services__hero--wrapper {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    overflow: visible;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .services__hero--info-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .services__hero--img-wrapper {
    right: 20px;
    left: 20px;
    top: auto;
    transform: none;
    bottom: -200px;
    width: auto;
    height: 400px;
  }

  #expertise {
    .flex-row {
      flex-direction: column;

      .left,
      .right {
        width: 100%;
        align-items: center;
      }

      .left {
        margin-bottom: 40px;
      }
    }
  }

  .expert__img--wrapper {
    width: 100%;
    height: 350px;
    margin-top: 40px;
  }

  .expert__img {
    left: 50%;
    transform: translateX(-50%);
    top: 40px;
    width: 90%;
    object-position: top;
  }
}

@media (max-width: 600px) {
  #services-page {
    .title {
      text-align: center;
    }
  }

  .services__hero--wrapper {
    .title {
      font-size: 36px;
    }
  }

  .expert__img--line {
    display: none;
  }

  .expert__img {
    width: 100%;
    height: 100%;
    transform: none;
    position: static;
  }
}

@media (max-width: 425px) {
  .package {
    width: 100%;
    padding: 28px 0;
    margin: 12px 0;
  }
}
