// Import variables
@use "../Config/variables.scss" as *;
@use "../Config/mixins.scss" as *;

// Base styles
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
}
