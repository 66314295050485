@use "./Config/variables.scss" as *;
@use "sass:map";

#notfound {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #232325;

  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  canvas {
    filter: blur(20px) brightness(0.8);
  }
}

.notfound--title {
  font-size: 200px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.328);
}

.notfound--para {
  font-size: 24px;
  color: white;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.328);
}

.notfound--btn {
  background-color: map.get($colors, primary-1);
  color: white;
  margin-top: 28px;
  padding: 8px 18px;
  font-weight: 500;
}