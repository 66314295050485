@use "../Config/variables.scss" as *;
@use "../Config/mixins.scss" as *;
@use "sass:map";

#about-page {
  section {
    .title {
      font-size: 42px;
    }

    .para {
      font-size: 16px;
    }
  }
}

#about-landing {
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("https://images.unsplash.com/photo-1670487274509-f17845d560e2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wzNjAzNTV8MHwxfHJhbmRvbXx8fHx8fHx8fDE3Mjc0NTg2MTl8&ixlib=rb-4.0.3&q=80&w=800");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 90vh;
  min-height: 600px;

  .container {
    height: 100%;

    .row {
      max-width: 1400px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;
    }
  }
}

.about--title {
  font-size: 96px;
  color: white;
  position: relative;
  padding: 12px 0;
}

.white-line {
  height: 1px;
  width: 500px;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.4);
}

.about__header-bottom {
  color: white;
  display: flex;
  justify-content: space-between;

  p {
    width: calc(100% / 3);
    line-height: 26px;
  }

  .white-line {
    width: calc(100% / 4);
    padding: 0 40px;
    margin-top: 10px;
  }
}

/*

FOUNDER

*/

.founder__img--wrapper {
  width: 100%;
  aspect-ratio: 1;
  overflow: hidden;
  border-radius: 10px;
  z-index: 5;

  img {
    transform: scale(1.5) rotate(-13deg);
    margin-top: 27%;
    margin-left: 32%;
  }
}

#founder {
  .left {
    z-index: 5;
  }

  .right {
    padding-left: 30px;
  }

  .title {
    font-size: 42px;
  }

  .para {
    font-size: 16px;
  }

  .stats--container {
    border: 1px solid map.get($map: $colors, $key: primary-1);
    border-left: none;
    border-right: none;
    padding: 20px 0;

    display: flex;

    .stat {
      display: flex;
      flex-direction: column;
      margin-right: 50px;

      font-size: 14px;

      .stat--number {
        font-size: 32px;
        font-weight: 700;

        .stat-plus {
          padding-left: 4px;
          color: map.get($map: $colors, $key: primary-1);
        }
      }
    }
  }
}

.founder__top-short {
  background-color: rgb(228, 228, 228);
  font-size: 16px;
  padding: 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  width: fit-content;
  margin-bottom: 30px;
}

/*

STORY

*/
#mission {
  .para {
    margin-bottom: 0;
  }
}
