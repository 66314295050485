@use '../Config/variables.scss' as *;
@use '../Config/mixins.scss' as *;
@use 'sass:map';

/*

HOME

*/

#landing {
  @include flexCenterVert;
  background-size: cover;
  background-color: map.get($colors, dark-1);
}

#landing--container {
  width: 100%;
  min-height: 100vh;
  min-height: max(100vh, 750px);
  @include flexCenterVert;
  color: map.get($colors, light-gray);

  .row {
    display: flex;
  }
}

.landing--left {
  width: 50%;
}

.landing--right {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.landing__img--wrapper {
  display: flex;
  overflow: hidden;
  border-radius: 20px;
  width: calc(40% / 4);
  height: 560px;
  position: relative;
  transition: all 600ms ease;
  margin-left: 20px;
}

.landing__img--open {
  width: 350px;
}

.landing__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.landing__img--info-box {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  display: flex;
  opacity: 0;
}

.landing__img--info-container {
  position: absolute;
  left: 100px;
  top: 5px;
  width: 400px;
  opacity: 0;
  transition: all 600ms ease;
  transition-delay: 100ms;
}

.landing__img--info-title {
  font-size: 18px;
  font-weight: 600;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.363);
}

.landing__img--info-text {
  font-size: 16px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.363);
}

.landing__img--open {
  .landing__img--info-box {
    opacity: 1;
  }

  .landing__img--info-container {
    opacity: 1;
    left: 66px;
  }
}

.landing__img--info-icon {
  height: 56px;
  width: 56px;
  background-color: #00000099;
  padding: 14px;
  border-radius: 10px;
  margin-right: 10px;
}

.landing--title {
  font-size: 56px;
  font-weight: 900;
  max-width: 100%;
  color: white;
  margin: 20px 0;
}

.landing--para {
  margin-bottom: 20px;
}

.landing--btn {
  margin-top: 20px;
  font-weight: 700;
  font-size: 14px;

  p {
    padding-right: 10px;
  }
}

/*

ABOUT

*/
#aboutsec {
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*

SERVICE

*/
.services--container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
}

.service {
  max-width: 370px;
  border-radius: 6px;
  background-color: map.get($colors, alternative-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 30px;
}

.service--icon {
  width: 100%;
  font-size: 46px;
  margin-bottom: 20px;
}

.service--title {
  margin-bottom: 10px;
}

/*

SKILLS

*/
#skills {
  max-width: 1050px;
  display: flex;
  margin: 0 auto;
}

.skill {
  background-color: #f4f4f4;
  padding: 20px;
  margin: 24px 0;
  border-radius: 6px;
}

.skill--title {
  margin-bottom: 8px;
  margin-top: 2px;
}

.skill--para {
  margin: 0;
}

.skills--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.skills__bars--container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 80px;
}

.skill__bar--wrapper {
  position: relative;
  background-color: #d9d9d9;
  height: 6px;
  width: 490px;
  border-radius: 4px;
  margin-bottom: 60px;
}

.skill__bar {
  height: 100%;
  width: 0%;
  border-radius: 4px;
  background-color: map.get($colors, primary-1);
  transition: width 1000ms ease;
}

.skill__bar--title {
  position: absolute;
  top: -22px;
  font-weight: 500;
  font-size: 16px;
}

.webdesign-bar {
  width: 95%;
}

.customerservice-bar {
  width: 98%;
}

.projectmanagement-bar {
  width: 93%;
}

.responsivedesign-bar {
  width: 92%;
}

/*

DESIGNS

*/
#designs {
  .row {
    max-width: 1400px;
  }

  .section-line {
    display: flex;
    margin: 0 auto;
  }

  .title {
    width: 100%;
    text-align: center;
  }

  .sub-title {
    margin-top: 20px;
    width: 100%;
    text-align: center;
  }
}

.designs--container {
  padding: 0 40px;
  width: 100%;
}

.design--wrapper {
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.design {
  width: 100%;
  max-width: 330px;
  min-width: 250px;
  background-color: rgb(242, 242, 242);
  padding: 12px;
  position: relative;
  border-radius: 4px;
}

.design__img--wrapper {
  width: 100%;
  border-radius: 5px;
  max-height: 500px;
  box-shadow: 0px 0px 34px 6px #bcc6ff;
  cursor: s-resize;

  .design__img {
    width: 100%;
    object-fit: cover;
    object-position: top;
    height: 500px;
    transition: object-position 2s ease;

    &:hover {
      object-position: bottom;
    }
  }
}

.design--title {
  position: absolute;
  background-color: map.get($colors, primary-1);
  top: -26px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  padding: 16px;
  height: 32px;
  @include flexCentered;
  border-radius: 4px;
  color: white;
}

/*

TESTIMONIALS

*/
#testimonials {
  margin: 80px 0;

  .title {
    text-align: center;
  }

  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .section-line {
    margin-bottom: 60px;
  }
}

.testimonials--container {
  width: 100%;
}

.testimonial {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 0 36px;
}

.testimonial__pfp--container {
  width: 400px;
  height: 400px;
  border-radius: 500px 0 500px 500px;
  overflow: hidden;
  border: 1px solid lightgray;

  img {
    height: 100%;
  }
}

.testimonial--right {
  width: calc(100% - 452px);
  width: calc(100% - 452px);
  display: flex;
  flex-direction: column;
  justify-self: flex-start;
  align-items: flex-start;
  height: 400px;
  padding: 20px 0;
  padding-left: 40px;
}

.testimonial__para {
  font-size: 26px;
  margin-bottom: 40px;
  line-height: 140%;
}

.testimonial__name {
  font-size: 40px;
  font-weight: bold;
}

.testimonial__job {
  font-size: 26px;
}

.testimonial_prev,
.testimonial_next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial_prev {
  left: 12px;
}

.testimonial_next {
  right: 12px;
}

.testimonial__btn-text {
  display: none;
}
